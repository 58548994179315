@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;1,300&family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
}
/* Reset general */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* Evita problemas con el tamaño real de los elementos debido al padding y border */
}

html{
    scroll-behavior: smooth;
}


/* Otros estilos comunes */
a {
    text-decoration: none;
    /* Elimina el subrayado predeterminado de los enlaces */
}

.swiper-pagination-bullet {
    width: 13px; /* Ancho del dot */
    height: 13px; /* Altura del dot */
    background-color: #D9D9D9; /* Color del dot inactivo */
    margin: 0 5px; /* Margen entre los dots */
    border: 2px solid #9c6f31;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background-color: #647865; /* Color del dot activo */
}
/* Puedes agregar más estilos comunes según tus necesidades */