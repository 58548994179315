.btn-animate {
  position: relative;
  overflow: hidden;
}

.btn-animate::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #d9d9d9;
  transition: height 0.5s ease;
  z-index: 0;
}

.btn-animate:hover::before {
  height: 100%;
}

.btn-animate span {
  position: relative;
  z-index: 1;
}

.btn-animate:hover span {
  color: #263649;
}
