@keyframes colorChange {
    0% {
        background-color: #9c6f31;
    }

    50% {
        background-color: #B2803A
    }

    100% {
        background-color: #9c6f31
    }
}

.color-animation {
    animation: colorChange 2s 2;
}