.btnc-animate {
    position: relative;
    overflow: hidden;
}

.btnc-animate::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #9c6f31;
    transition: height 0.5s ease;
    z-index: 0;
}

.btnc-animate:hover::before {
    height: 100%;
}

.btnc-animate span {
    position: relative;
    z-index: 1;
}

.btnc-animate:hover span {
    color: #302e31;
}

.btnc-touch-animate:active {
    background-color: #9c6f31;
    color: #302e31;
}